import "bootstrap/dist/css/bootstrap.min.css";
import * as React from "react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Home from "../components/Home";
import "../styles/custom.css";
import "../styles/responsive.css";
import "../styles/style.css";

const IndexPage = () => {
  SwiperCore.use([Autoplay, Navigation]);
  React.useEffect(() => {
    if (!window.console) window.console = {};
    var methods = ["log", "debug", "warn", "info", "error"];
    for (var i = 0; i < methods.length; i++) {
      console[methods[i]] = function () {};
    }
  }, []);

  return <Home />;
};

export default IndexPage;
